<template>
  <div>
    <br class="d-none d-sm-block" />

    <div class="table">
      <div class="table-header">
        <v-row class="d-none d-md-flex">
          <v-col cols="5" class="caption">
            {{ $t('employeeDetails.firstName') }}
          </v-col>

          <v-col cols="5" class="caption">
            {{ $t('employeeDetails.lastName') }}
          </v-col>
          <v-col cols="2" />
        </v-row>
      </div>

      <div
        v-if="value && !loading && value.length > 0"
        class="overview-body table-body"
      >
        <br class="d-none d-sm-block" />
        <template v-for="(employee, index) in value">
          <target-employee-item
            :value="employee"
            :key="`target_employee_table_item_${employee.id}`"
            :itemIndex="index"
          ></target-employee-item>
        </template>
      </div>
      <div v-else-if="loading" class="mt-4">
        <v-row>
          <v-col cols="12">
            <v-progress-linear indeterminate></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <div v-else class="mt-4">
        <v-row>
          <v-col cols="12">
            <v-alert text type="info">
              {{ $t('noEmployeesAvailable') }}
            </v-alert>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import targetEmployeeItem from '@/components/feedbacks/targets/target-employee-item.vue';

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    targetEmployeeItem
  }
};
</script>

<style lang="scss" scoped>
.overview-body .row .expandable:hover {
  cursor: pointer;
}

.overview-body .row.table-row-divider:hover {
  background: none;
}

.table {
  margin-left: -16px;
  margin-right: -16px;
}

.table .row.table-row-divider {
  margin-top: 0;
}

.table .row.table-row-divider > div {
  padding-left: 0;
  padding-right: 0;
  padding: 0;
}

.expanded-content {
  background-color: var(--element-background-secondary);

  .row {
    align-items: center;
  }

  .row:after {
    display: block;
    content: ' ';
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.1);
  }

  .row:last-of-type:after {
    border-bottom: none;
  }
}
</style>
