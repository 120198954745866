<template>
  <div>
    <template v-if="isFeedbackDonor && hasMafegProcess">
      <v-card flat class="segmented">
        <v-tabs v-model="tabIndex" grow :vertical="isVertical">
          <v-tab>{{ $t('targets.myTargets') }}</v-tab>
          <v-tab>{{ $t('targets.targetsAsDonor') }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabIndex">
          <v-tab-item>
            <v-card-text>
              <targets-tables :readOnly="false" />
            </v-card-text>
          </v-tab-item>

          <v-tab-item>
            <v-card-text>
              <targets-employees-table
                v-model="employees"
                :loading="employeesLoading"
                readOnly
              />
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
    <template v-else-if="employeesTargetsOnly">
      <v-card flat class="segmented">
        <v-card-title>
          <h5>
            {{ $t('targets.targetsAsDonor') }}
          </h5>
        </v-card-title>

        <v-card-text>
          <targets-employees-table
            v-model="employees"
            :loading="employeesLoading"
            readOnly
          />
        </v-card-text>
      </v-card>
    </template>
    <template v-else-if="!isFeedbackDonor && hasMafegProcess">
      <v-card flat class="segmented">
        <v-card-title>
          <h5>
            {{ $t('targets.myTargets') }}
          </h5>
        </v-card-title>

        <v-card-text>
          <targets-tables :readOnly="false" />
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import targetsTables from '@/components/feedbacks/targets/targets-tables.vue';
import targetsEmployeesTable from '@/components/feedbacks/targets/targets-employees-table.vue';
import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  data() {
    return {
      employeesLoading: true,
      tabIndex: null,
      employees: []
    };
  },

  watch: {
    async tabIndex() {
      if (this.tabIndex === 1 && this.employeesLoading) {
        await this.fetchUsers();
        this.employeesLoading = false;
      }
    },

    async employeesTargetsOnly() {
      if (this.employeesTargetsOnly && this.employeesLoading) {
        await this.fetchUsers();
        this.employeesLoading = false;
      }
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    }),

    employeesTargetsOnly() {
      return this.isFeedbackDonor && !this.hasMafegProcess;
    },

    hasMafegProcess() {
      return this.currentUser?.hasMafegProcess;
    },

    isFeedbackDonor() {
      if (!this.currentUser) {
        return false;
      }

      return this.currentUser.isSupervisor || this.currentUser.isFeedbackDonor;
    },

    isVertical() {
      const bpName = this.$vuetify.breakpoint.name;
      return bpName === 'xs' || bpName === 'sm';
    }
  },

  async mounted() {
    if (this.employeesTargetsOnly && this.employeesLoading) {
      await this.fetchUsers();
      this.employeesLoading = false;
    }

    const userId = this.$route.query.uid;
    if (userId) {
      this.tabIndex = 1;
    }
  },

  methods: {
    ...mapActions({
      fetchFeedbacksAsDonor: 'feedbacks/listFeedbacksAsDonor',
      fetchFeedbacksFromHierarchy: 'feedbacks/listFeedbacksFromHierarchy'
    }),

    async fetchUsers() {
      const [asDonor, fromHierarchy] = await Promise.all([
        this.fetchFeedbacksAsDonor(),
        this.fetchFeedbacksFromHierarchy()
      ]);

      var asDonorMapped = asDonor
        .filter((x) => x.processType === feedbackEnums.processTypes.MAFEG)
        .map((x) => {
          return {
            id: x.recipientId,
            firstname: x.recipientFirstName,
            lastname: x.recipientLastName
          };
        });

      var fromHierarchyMapped = fromHierarchy
        .filter((x) => x.processType === feedbackEnums.processTypes.MAFEG)
        .map((x) => {
          return {
            id: x.recipientId,
            firstname: x.recipientFirstName,
            lastname: x.recipientLastName
          };
        });

      const allUsers = fromHierarchyMapped.concat(asDonorMapped);
      this.employees = allUsers;
    }
  },

  components: {
    targetsTables,
    targetsEmployeesTable
  }
};
</script>
